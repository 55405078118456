import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import Button from "@ui/button";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Text from "@ui/text";
import List, { ListItem } from "@ui/list";
import {
  BoxSectionWrapper,
  BoxSectionInner,
  BoxSectionWrap,
  BoxSectionWrapInner,
  BoxSectionIconwrap,
  BoxSectionTextWrap,
  BoxBottomImgWrap,
  ButtonWrap,
} from "./style";

const BoxSection = ({ data, layout, ...props }) => {
  return (
    <BoxSectionWrapper layout={layout} {...props} id={data.section}>
      <BoxSectionInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BoxSectionWrap>
                {data &&
                  data.items.map((el, index) => (
                    <BoxSectionWrapInner key={index}>
                      <BoxSectionIconwrap>
                        <Image
                          src={el.images[0]?.src}
                          alt={el.images[0]?.alt || "Icon"}
                        />
                      </BoxSectionIconwrap>
                      <BoxSectionTextWrap layout={layout}>
                        {el && el.title && (
                          <Heading as="h6" className="text-white">
                            {el.title}
                          </Heading>
                        )}
                        {el && el.description && <Text>{el.description}</Text>}
                        <List $layout="circle">
                          {!!el.list &&
                            el.list.map((el, index) => (
                              <ListItem
                              key = {index}
                                dangerouslySetInnerHTML={{ __html: el.text }}
                              ></ListItem>
                            ))}
                        </List>
                      </BoxSectionTextWrap>
                    </BoxSectionWrapInner>
                  ))}
              </BoxSectionWrap>
            </Col>
          </Row>
          {!!data.images && (
            <Row>
              <Col>
                <BoxBottomImgWrap>
                  <Image
                    src={data.images[0]?.src}
                    alt={data.images[0]?.alt || "Image"} />
                </BoxBottomImgWrap>
              </Col>
            </Row>
          )}

        { data?.buttons &&
          <Row>
              <Col>
                <ButtonWrap className="vlbtn">
                  {data?.buttons?.map(({ id, path, content, ...rest }) => (
                    <Button className="btn-learn-more" key={id} path={path} {...rest}>
                      {content}
                    </Button>
                  ))}
                </ButtonWrap>
              </Col>
            </Row>
          }
        </Container>
      </BoxSectionInner>
    </BoxSectionWrapper>
  );
};

BoxSection.propTypes = {
  layout: PropTypes.oneOf([1, 2, 3, 4, 5]),
  items: PropTypes.object,
};
BoxSection.defaultProps = {
  layout: 1,
};

export default BoxSection;
